<template>
  <LayoutPopupSkeleton class="options-popup">
    <div class="options-popup__title">
      <span class="options-popup__font options-popup__font--title">
        {{ title }}
      </span>
    </div>

    <div class="options-popup__options">
      <button
        v-for="(option, index) in options"
        :key="index"
        class="options-popup__button"
        @click="callbackFunction(option)"
      >
        <span class="options-popup__font options-popup__font--button">
          {{ option.text }}
        </span>
      </button>
    </div>

    <div v-if="description" class="options-popup__description">
      <span
        class="options-popup__font options-popup__font--description"
        v-html="description"
      />
    </div>
  </LayoutPopupSkeleton>
</template>

<script>
import LayoutPopupSkeleton from "@/components/layout/PopupSkeleton.vue";

export default {
  name: "OptionsPopup",
  components: { LayoutPopupSkeleton },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    description: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    callbackFunction: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style scoped lang="scss">
.options-popup {
  color: $color-dark;

  &__title {
    text-align: center;
    margin: 0 auto 16px;
  }

  &__options {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__button {
    background-color: $color-white;
    border: 1px solid $color-light-gray;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 36px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__description {
    color: $color-gray-1;
  }

  &__font {
    &--title {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 21px;
      font-weight: 700;
      line-height: 28px;
    }

    &--button {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }

    &--description {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.035em;
    }
  }
}
</style>
