<template>
  <div class="layout-popup-skeleton">
    <button class="layout-popup-skeleton__button" @click="closePopup">
      <img
        class="layout-popup-skeleton__icon"
        src="/images/icons/cross.svg"
        alt="cross"
      />
    </button>

    <slot />
  </div>
</template>

<script>
export default {
  name: "LayoutPopupSkeleton",
  methods: {
    closePopup() {
      this.$popup.close();
    },
  },
};
</script>

<style scoped lang="scss">
.layout-popup-skeleton {
  position: relative;
  background-color: $color-white;
  padding: 32px 24px;
  border-radius: 24px;
  max-width: 343px;

  &__button {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: transparent;
  }
}
</style>
